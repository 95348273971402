<template>
  <div class="req-temp-container">
    <vs-card class="mt-6" v-if="isPayLater">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-normal text-xl md:text-2xl">Products</h3>
        </div>
      </div>
      <div>
        <vs-row class="grid md:grid-cols-3 lg:grid-cols-3 gap-x-6">
          <vs-col v-for="(plan, index) in products" :key="index" class="flex">
            <vs-card class="product-card shadow-none">
              <div slot="header">
                <div class="items-center">
                  <h5 class="plan-name mb-2 font-normal">
                    {{ plan.productName }}
                  </h5>
                  <p>
                    <span class="plan-text">{{ plan.customPlanId ? plan.customPlanId : plan.planId }}</span>
                  </p>
                </div>
              </div>
              <div>
                <p class="block pb-6 plan-text">
                  {{ plan.planId.productDescription }}
                </p>
                <div class="flex mb-6">
                  <div class="w-full">
                    <label class="plan-text">{{ plan.planId.feeModel === "MSF_INCLUDED" ? "Fee deducted from your disbursement" : "Fee added to repayment" }}</label>
                    <div class="flex mt-2">
                      <span class="text-base plan-text">Service fee :</span><span class="pl-2 text-base text-black font-light">{{ addZeroes(plan.overrideServiceFee ? plan.msf : plan.planId.msf) }} %</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mt-3 mb-6" v-if="plan.planId.isPayNow">
                <div class="w-full flex" v-if="merchant.eftType == 'merchant'">
                  <vs-switch v-model="plan.displayBankDetail" />
                  <label class="plan-text mb-2 ml-2">Display my bank details as EFT option</label>
                </div>
                <div class="w-full" v-else>
                  <label class="plan-text mb-2">When displaying bank transfer option</label> <br>
                  <vs-radio v-model="plan.eftType" vs-value="PLATFORM_BANK_ACCOUNT" :vs-name="`eftType-${index}`" class="mt-2">Use {{ appName }} payment service</vs-radio>
                  <vs-radio v-model="plan.eftType" vs-value="MERCHANT_BANK_ACCOUNT" :vs-name="`eftType-${index}`" class="mt-2">Use my bank account</vs-radio>
                  <vs-radio v-model="plan.eftType" vs-value="DO_NOT_DISPLAY" :vs-name="`eftType-${index}`" class="mt-2">Don't offer bank transfers</vs-radio>
                </div>
              </div>
              <div class="w-full">
                <div class="ds-wrapper">
                  <label class="plan-text mb-2">Default reference on disbursement transaction
                    <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '50px, 2px' }}}">
                      <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                      <div class="popper py-2 px-4 text-center custom-popper">
                        <p class="text-primary">
                          You can set the reference we'll use on our bank transfer to you, for disbursements initiated using this product. If you don't enter one at the time of creating a request,
                          we'll use the data available in the request to create a reference for you. We'll use the first available data field,
                          in order of what you select here - drag and drop items to order them from first preference to last.
                        </p>
                      </div>
                    </popper>
                  </label>
                </div>
                <draggable :list="plan.referenceOrder" class="cursor-move list-draggable mt-2">
                  <transition-group>
                    <vs-list-item
                      v-for="listItem in plan.referenceOrder"
                      :key="listItem.value + 0.1"
                      class="list-item"
                      v-if="(listItem.value !== 'PRODUCT_FIELD') || (listItem.value === 'PRODUCT_FIELD' && plan.planId.customFields && plan.planId.customFields.length > 0)"
                    >
                      <div class="flex items-center">
                        <feather-icon icon="ListIcon" svgClasses="h-3 w-3" />
                        <p class="text-sm font-normal text-black ml-1">{{ listItem.label }}</p>
                      </div>
                      <template v-if="listItem.value === 'PRODUCT_FIELD' && plan.planId.customFields && plan.planId.customFields.length > 0">
                        <vs-select class="mb-0 mt-2 ml-3" name="productField" id="productField" placeholder="Select a field" v-model="listItem.productField" size="small">
                          <vs-select-item :value="item.customFieldId" :text="item.label" v-for="(item, fieldIndex) in plan.planId.customFields" :key="item.label + fieldIndex " />
                        </vs-select>
                      </template>

                    </vs-list-item>
                  </transition-group>
                </draggable>
              </div>
              <div v-if="plan.repaymentBy" class="mt-4">
                <label class="plan-text mb-2">Repayment facilitated by:</label><br>
                <p class="text-base text-black font-light pt-1">{{ plan.repaymentBy }}</p>
              </div>
              <div class="flex mt-2" v-if="plan.planId.productConfigType === 'THREE_PARTY'">
                <vs-switch v-model="plan.automatedReminder" name="automatedReminder"></vs-switch>
                <label class="plan-text mb-2 ml-5">Send automated reminder for unpaid requests</label>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="block">
        <div class="button-section mb-4 mt-6 md:mt-8">
          <vs-button class="primary lg:mr-6 mr-4" @click="updateDisplayName()" size="large" v-if="isPayLater" v-round>Save</vs-button>
          <a class="mid-blue text-button text-sm pl-1" @click="$router.push({ name: 'settings' })">Cancel</a>
        </div>
      </vs-row>
    </vs-card>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import Popper from 'vue-popperjs';

import moment from "moment";
import LeavePopup from "@/views/components/LeavePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";

export default {
  mixins: [leavePopUpMixin],
  components: { draggable, Popper, LeavePopup, ToolTipIcon },
  name: "ProductList",
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      products: [],
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
      isPayLater: false,
      merchant: "",
      defaultReferenceOrder: [
        {
          label: "Merchant reference",
          value: "MERCHANT_REFERENCE",
          productField: ""
        },
        {
          label: "External ID (from HPP)",
          value: "EXTERNAL_ID",
          productField: ""
        },
        {
          label: "Payment request ID",
          value: "PAYMENT_REQUEST_ID",
          productField: ""
        },
        {
          label: "A product field",
          value: "PRODUCT_FIELD",
          productField: ""
        },
      ],
      repaymentByList: ""
    };
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    isValidField() {
      let status = false;

      this.products.map((item) => {
        if (item.displayName === "") {
          status = true;
        }
      });

      return status;
    },
  },
  methods: {
    ...mapActions("paymentPlans", ["updatePartnerPlans"]),
    ...mapActions("partner", ["getPayLaterProductsByPartnerId"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("setting", ["fetchSetting"]),

    async getAllMerchantProducts() {
      this.$vs.loading();
      let partnerId = this.user.userType === "admin" ? this.user._id : this.user.partnerId;

      await this.getPayLaterProductsByPartnerId(partnerId).then((response) => {
        this.merchant = response.data.data;
        this.products = response.data.data.payLaterPlans.map((el) => {
          if (el.displayName === "") {
            el.displayName = el.productName;
          }

          if (!el.referenceOrder || !el.referenceOrder.length) {
            el.referenceOrder = JSON.parse(JSON.stringify(this.defaultReferenceOrder));
          }

          if (el.planId && el.planId.customFields && el.planId.customFields.length) {
            el.planId.customFields = el.planId.customFields.filter(item => item.type !== "file");
          }

          el.referenceOrder = el.referenceOrder.filter(item => item.value != "EXTERNAL_ID");

          if (el.repaymentBy && el.repaymentBy !== "") {
            const repayment = this.repaymentByList.find(item => item._id == el.repaymentBy);
            el.repaymentBy = (repayment && repayment.title) ? repayment.title : "";
          }

          el.automatedReminder = this.$lodashHelper.checkObjectKey(el, "automatedReminder", false);

          return el;
        });

        this.isPayLater = response.data.data.requestOptions.includes("pay-later");
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
          this.$vs.notify({
          title: "Products",
          text: ex.response.data.message,
          iconPack: "feather",
          icon: "icon-times-circle",
          color: "danger",
          position: "top-right",
        });

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },

    async updateDisplayName() {
      this.$vs.loading();

      let products = this.products.map(item => {
        let data = item;
        data.planId = data.planId._id;
        return data;
      });

      const payload = {
        id: this.user.userType === "admin" ? this.user._id : this.user.partnerId,
        data: products,
      };

      await this.updatePartnerPlans(payload).then((response) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Products",
          text: "Products updated",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.isSaved = true;
        this.$router.push({ name: "settings" });
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    checkForm() {
      this.checkFormDirty = true;
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },

    addZeroes(num) {
      num = String(num);
      num.split(".")[1];
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    async getRepaymentBy() {
      await this.fetchSetting("repaymentBy").then((result) => {
        this.repaymentByList = result.data.data.filter(item => item.active);
      }).catch((ex) => {
        this.$vs.notify({
          title: ex.response.data.title,
          text: ex.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  },

  mounted() {
    this.getAllMerchantProducts();
    this.checkAdminLogin();
    this.getRepaymentBy();
  },
};
</script>

